<!-- 后厨管理-制作列表 -->

<template>
	<div class="create-list cursor-pointer" @click="onClickBtn">
		<div class="flex-btw s-m-b create-title">
			<div class="flex flex-shrink">
				<div class="">桌号：</div>
				<div>{{ rowDatas.odrg_scene == '1' ? '外卖' : rowDatas.odrg_tableno }}</div>
			</div>
			<div class="flex flex-shrink">
				<div>单号：</div>
				<div>{{ rowDatas.odrg_number_sn }}</div>
			</div>
		</div>
		<div v-if="rowDatas.orderdetail.length > 0" class="create-content">
			<div v-for="(item, i) in rowDatas.orderdetail" :key="i">
				<div class="flex-btw s-m-b">
					<div :class="item.is_repeat ? 'text-orange' : ''">{{ item.drg_name }}</div>
					<div>x{{ item.odrgd_amount }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, watch, computed, } from 'vue';
	import { useStore, } from "vuex";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		},
		{
			deep: true,
			immediate: true,
		}
	);
	const emit = defineEmits(['onClickBtn',])
	const props = defineProps({
		rowDatas: {  // 当前行数据
			type: Object,
			default: () => { }
		},
	})
	const isLoading = ref(false);  // 按钮加载状态
	/**
	 * 
	 * 点击按钮
	 * 
	 * **/
	const onClickBtn = () => {
		isLoading.value = true;
		emit('onClickBtn', props.rowDatas);
		setTimeout(() => {
			isLoading.value = false;
		}, 1000)
	}
</script>

<style lang="scss" scoped>
	.create-list {
		font-family: "Source Han Sans CN";
		margin: 10px;
		padding: 10px;
		border-radius: 5px;
		background-color: #f3f4f8;
		min-height: 320px;

		.felx {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.s-m-l {
			margin-left: 10px;
		}

		.m-m-l {
			margin-left: 15px;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.grey-text {
			color: var(--text-second-color);
		}

		.text-orange {
			color: #fca144;
		}

		.create-title {
			border-bottom: 1px solid #e9ebef;
			padding-bottom: 10px;
			font-weight: bold;
			color: var(--text-color);
		}

		.create-content {
			font-size: 14px;
			color: var(--text-second-color);
		}

		.create-list-item {
			padding: 10px 16px;
			margin: 0 10px 10px 10px;
			border: 1px solid var(--search-uncheck-bg-color);
			border-radius: 8px;
			line-height: 42px;
			background-color: var(--text-white-color);
			font-size: 18px;
			/* box-shadow: 2px 2px 1px 1px rgba(0,0,0,0.1); */
		}
	}
</style>