<!-- 后厨管理 -->

<template>
	<div class="main-cnts" id="middle" :class="isShowMake ? 'middle' : ''">
		<div v-if="isShowMake">
			<div class="flex flex-wrap restaurant">
				<div v-for="(item, i) in makeOptions" :key="i" class="restaurant-content flex-btw">
					<div>
						<div class="restaurant-title">{{ item.dr_name }}</div>
						<el-tooltip effect="light" :content="item.dr_introduce" placement="bottom">
							<div class="text-hidden restaurant-introduce">{{ item.dr_introduce }}</div>
						</el-tooltip>
						<div>
							<el-button type="primary" plain @click="onClickName(item)" class="start-production-btn">开始制作
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="kitchen-manage" v-else>
			<div class="kitchen-content">
				<el-row :gutter="20" style="height: 100%;">
					<el-col :span="9">
						<div class="make">
							<!-- 标题 -->
							<div class="make-title">
								<div class="text-white to-be-produced make-title-text">待制作/COMING</div>
							</div>

							<!-- 列表 -->
							<el-scrollbar class="scrollbar-list">
								<div class="s-p-b">
									<div v-if="producedLists.length == 0" class="no-data">暂无数据</div>
									<el-row>
										<el-col :span="12" v-for="(item, index) in producedLists" :key="index">
											<CreateList :rowDatas="item" @onClickBtn="onMakeBtn"></CreateList>
										</el-col>
									</el-row>
								</div>
							</el-scrollbar>

							<div class="flex-end produced-paging">
								<div>
									共<span>{{ producedConfig.total }}</span>条
								</div>
								<el-pagination v-model="producedConfig.Fpage" :page-size="producedConfig.limit"
									background layout="prev, pager, next, jumper" :total="producedConfig.total"
									@current-change="producedCurrentChange">
								</el-pagination>
							</div>
						</div>
					</el-col>

					<el-col :span="9">
						<div class="make">
							<!-- 标题 -->
							<div class="make-title">
								<div class="text-white make-title-text in-production">制作中/ING</div>
							</div>

							<!-- 列表 -->
							<el-scrollbar class="scrollbar-list">
								<div class="s-p-b">
									<div v-if="productionLists.length == 0" class="no-data">暂无数据</div>
									<el-row>
										<el-col :span="12" v-for="(item, index) in productionLists" :key="index">
											<CreateList :rowDatas="item" @onClickBtn="onProductioncompletedBtn">
											</CreateList>
										</el-col>
									</el-row>
								</div>
							</el-scrollbar>

							<div class="flex-end produced-paging">
								<div>
									共<span>{{ productionConfig.total }}</span>条
								</div>
								<el-pagination v-model="productionConfig.Fpage" :page-size="productionConfig.limit"
									background layout="prev, pager, next, jumper" :total="productionConfig.total"
									@current-change="productionCurrentChange">
								</el-pagination>
							</div>
						</div>
					</el-col>

					<el-col :span="6">
						<div class="make">
							<!-- 标题 -->
							<div class="make-title">
								<div class="text-white production-completed make-title-complete-text">制作完成/COMPLETE
								</div>
							</div>

							<!-- 列表 -->
							<el-scrollbar class="scrollbar-list">
								<div class="s-p-b">
									<div v-if="completeLists.length == 0" class="no-data">暂无数据</div>
									<div v-else class="flex complete-lists">
										<div v-for="(item, index) in completeLists" :key="index"
											class="complete-list-item">
											单号：{{item.odrg_number_sn}}
										</div>
									</div>
								</div>
							</el-scrollbar>

							<div class="flex-end produced-paging">
								<div>
									共<span>{{ completeConfig.total }}</span>条
								</div>
								<el-pagination v-model="completeConfig.Fpage" :page-size="completeConfig.limit"
									background layout="prev, pager, next, jumper" :total="completeConfig.total"
									@current-change="completeCurrentChange">
								</el-pagination>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div v-if="!isShowMake" @click="onReturnBtn" class="return-btn">
			<img src="@/assets/img/fh.png" style="width: 20px;height: 20px;" />
			<div>返回</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, onMounted, computed, watch, } from 'vue';
	import { DinnerApi, } from '@/plugins/api.js';
	import CreateList from "../components/createList.vue";
	import { ElMessage, ElNotification, } from "element-plus";
	import { useStore } from "vuex";

	const producedLists = ref([]);  // 待制作列表数据
	const productionLists = ref([]);  // 制作中列表数据
	const completeLists = ref([]);  // 制作完成数据
	const store = useStore();
	const isShowMake = ref(true);  // 是否显示选择制作
	const makeOptions = ref([]);  // 选择制作点选项
	const screen_id = ref('');  // 选择制作点id
	const producedConfig = ref({  // 待制作
		limit: 4,
		page: 1,
		total: 0,
	});
	const productionConfig = ref({  // 制作中
		limit: 4,
		page: 1,
		total: 0,
	});
	const completeConfig = ref({  // 制作完成
		limit: 50,
		page: 1,
		total: 0,
	});
	// const wsMessageEvent = computed(() => store.state.Config.wsMessageEvent);
	// watch(wsMessageEvent, () => {
	// 	// 监听是否有新订单
	// 	if (wsMessageEvent.value.business == 'goods_make') {
	// 		if (wsMessageEvent.value.type == 'goods_make_notice' || wsMessageEvent.value.type == 'goods_make_reload') {
	// 			getMakeLists();
	// 			if (wsMessageEvent.value.type == 'goods_make_notice') {
	// 				ElNotification({
	// 					title: '提示',
	// 					message: '您有新的订单，请及时处理！',
	// 					type: 'success',
	// 				})
	// 			}
	// 		}
	// 	}
	// });
	/**
	 * 
	 * 获取后厨制作列表
	 *
	 * **/
	const goodsMakeScreenList = () => {
		DinnerApi.getRangDinnerRoom().then((res) => {
			if (res.Code === 200) {
				makeOptions.value = res.Data;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 选择制作点
	 *
	 * **/
	const onClickName = (item) => {
		screen_id.value = item.dr_id;
		getMakeLists(3);
		getMakeLists(4);
		getMakeLists(5);
	}
	/**
	 * 
	 * 获取制作列表数据
	 *
	 * **/
	const getMakeLists = (type) => {
		let params = {
			dr_id: screen_id.value,
			odrg_status: type,
		};
		if (type == 3) {
			// 待制作
			params.limit = producedConfig.value.limit;
			params.page = producedConfig.value.page;
		} else if (type == 4) {
			// 制作中
			params.limit = productionConfig.value.limit;
			params.page = productionConfig.value.page;
		} else if (type == 5) {
			// 制作完成
			params.limit = completeConfig.value.limit;
			params.page = completeConfig.value.page;
		}
		DinnerApi.getDinnerKitchenLists(params).then((res) => {
			if (res.Code === 200) {
				isShowMake.value = false;
				document.getElementById('left-nav').style.display = 'none';
				document.getElementById('top').style.display = 'none';
				document.getElementById('main-wp').style.display = 'block';
				if (type == 3) {
					// 待制作
					producedLists.value = res.Data.list;
					producedConfig.value.total = res.Data.total;
				} else if (type == 4) {
					// 制作中
					productionLists.value = res.Data.list;
					productionConfig.value.total = res.Data.total;
				} else if (type == 5) {
					// 制作完成
					completeLists.value = res.Data.list;
					completeConfig.value.total = res.Data.total;
				}
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 制作按钮
	 *
	 * **/
	const onMakeBtn = (data) => {
		changeStatus(data, 4);
	}
	/**
	 * 
	 * 制作完成按钮
	 *
	 * **/
	const onProductioncompletedBtn = (data) => {
		changeStatus(data, 5);
	}
	/**
	 * 
	 * 改变状态
	 *
	 * **/
	const changeStatus = (data, type) => {
		DinnerApi.updateDinnerStatus({
			odrg_id: data.odrg_id,
			odrg_status: type,
		}).then((res) => {
			if (res.Code === 200) {
				getMakeLists(3);
				getMakeLists(4);
				getMakeLists(5);
				ElMessage.success("操作成功");
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 待制作页码改变处理
	 *
	 * **/
	const producedCurrentChange = (number) => {
		producedConfig.value.page = number;
		getMakeLists(3);
	};
	/**
	 * 
	 * 制作中页码改变处理
	 *
	 * **/
	const productionCurrentChange = (number) => {
		productionConfig.value.page = number;
		getMakeLists(4);
	};
	/**
	 * 
	 * 制作完成页码改变处理
	 *
	 * **/
	const completeCurrentChange = (number) => {
		completeConfig.value.page = number;
		getMakeLists(5);
	};
	/**
	 * 
	 * 点击返回按钮
	 * 
	 * **/
	const onReturnBtn = () => {
		location.reload();  // 强制刷新页面
	}
	onMounted(() => {
		document.getElementById('top-menu').style.display = 'none';
		goodsMakeScreenList();
	});
</script>

<style lang="scss" scoped>
	.main-cnts {
		height: calc(100% - 200px);
		border-radius: 10px;
		padding: 10px 10px 25px 10px;
		background-color: #e2effe;
	}

	.make-point-name {
		margin-top: 10px;
		font-size: 14px;
		background-color: var(--search-bg-color);
		color: var(--theme-color);
		padding: 10px 15px;
		border-radius: 5px;
		margin-right: 10px;
	}

	.middle {
		display: flex;
		justify-content: space-between;
		background-color: var(--bg-color);
	}

	.return-btn {
		position: fixed;
		right: 10px;
		bottom: 12%;
		z-index: 100;
		background: var(--theme-color);
		color: #fff;
		width: 55px;
		height: 55px;
		text-align: center;
		border-radius: 50%;
		font-size: 14px;
		cursor: pointer;
		padding: 5px 0;
	}

	.kitchen-manage {
		font-family: "Source Han Sans CN";
		margin-top: 10px;

		.xs-m-l {
			margin-left: 5px;
		}

		.s-m-l {
			margin-left: 10px;
		}

		.m-m-l {
			margin-left: 15px;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.s-p-b {
			padding-bottom: 10px;
		}

		.text-blue {
			color: var(--theme-color);
		}

		.text-white {
			color: var(--text-white-color);
		}

		.text-black {
			color: var(--text-second-color);
		}

		.to-be-produced {
			background-color: #0072f6;
		}

		.in-production {
			background-color: #ffba13;
		}

		.production-completed {
			background-color: #36c67d;
		}

		.no-data {
			text-align: center;
			padding: 20px 0;
		}

		.kitchen-content {
			margin: 0 25px;
			height: 90vh;
		}

		.make {
			border-radius: 15px;
			height: 100%;
		}

		.make-title {
			background-color: var(--text-white-color);
			border-radius: 10px 10px 0 0;
		}

		.make-title-text {
			border-radius: 10px 0 15px 0;
			font-size: 24px;
			width: 50%;
			padding: 10px;
		}

		.make-title-complete-text {
			border-radius: 10px 0 15px 0;
			font-size: 24px;
			width: 70%;
			padding: 10px;
		}

		.scrollbar-list {
			height: calc(100vh - 190px);
			background-color: var(--text-white-color);
			padding-top: 10px;
		}

		.produced-paging {
			background-color: var(--text-white-color);
			border-radius: 0 0 10px 10px;
			color: var(--el-text-color-regular);
			font-size: 13px;
			line-height: 28px;
			padding: 10px;
		}

		.subtitle {
			padding: 0 20px 10px 20px;
			font-size: 18px;
		}

		.complete-lists {
			flex-flow: wrap;
			margin-top: 10px;
		}

		.complete-list-item {
			padding: 0 20px;
			margin: 0 10px 10px 10px;
			border-radius: 5px;
			line-height: 42px;
			background-color: #f3f4f8;
			color: var(--text-second-color);
			font-size: 14px;
			font-weight: bold;
		}
	}

	.restaurant-content {
		width: 350px;
		/* background: linear-gradient(to bottom, #e6f1fe 0%, #ffffff 40%); */
		margin-right: 40px;
		margin-bottom: 20px;
		border-radius: 6px;
		padding: 16px;
		background-image: url('~@/assets/img/kitchen-bg.png');
		/* 设置图片路径 */
		background-size: cover;
		/* 背景图片覆盖整个元素 */
		background-repeat: no-repeat;
		/* 背景图片不重复 */
		background-position: center;
		/* 背景图片居中 */
	}

	.restaurant-title {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
		color: var(--text-color);
	}

	.restaurant-introduce {
		margin-bottom: 25px;
		width: 200px;
		font-size: 14px;
		color: var(--text-third-color);
	}

	.start-production-btn {
		width: 60px;
		padding: 0;
		color: var(--text-white-color);
		background-color: var(--theme-color);
		min-height: 25px;
		max-height: 25px;
		font-size: 12px;
	}
</style>